var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "board__list"
  }, [_c('v-row', {
    staticClass: "head-tab mb-10 mb-lg-20",
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "mb-20 mb-lg-0",
    attrs: {
      "cols": "12",
      "lg": ""
    }
  }, [_c('h3', {
    staticClass: "font-size-20 font-size-lg-28"
  }, [_vm._v(" 묻고 답하기 ")])])], 1), _c('div', {
    staticClass: "mb-20 mb-lg-40"
  }, [_c('table', {
    staticClass: "board-list board-list--main"
  }, [_vm._m(0), _c('tbody', _vm._l(_vm.questions, function (question) {
    return _c('tr', {
      key: question._id,
      on: {
        "click": function ($event) {
          return _vm.rowClick(question);
        }
      }
    }, [_c('td', {
      staticClass: "board-list__txt board-list__txt--first",
      attrs: {
        "align": "center"
      }
    }, [_vm._v(" " + _vm._s((_vm.page - 1) * _vm.limit + (+_vm.questions.indexOf(question) + 1)) + " ")]), _c('td', {
      staticClass: "board-list__txt",
      attrs: {
        "align": "center"
      }
    }, [!question.isPassword ? _c('i', {
      staticClass: "icon icon-lockoff"
    }) : _c('i', {
      staticClass: "icon icon-lockon"
    })]), _c('td', {
      staticClass: "board-list__tit",
      attrs: {
        "align": "center"
      }
    }, [_c('p', {
      staticClass: "grey-6--text page-text ellip"
    }, [_vm._v(" " + _vm._s(question === null || question === void 0 ? void 0 : question.subject) + " ")])]), _c('td', {
      staticClass: "board-list__txt",
      attrs: {
        "align": "center"
      }
    }, [!question.reply.content ? _c('span', {
      staticClass: "page-text page-text--sm"
    }, [_vm._v("대기중")]) : _c('span', {
      staticClass: "page-text page-text--sm primary--text"
    }, [_vm._v("답변완료")])]), _c('td', {
      staticClass: "board-list__txt",
      attrs: {
        "align": "center"
      }
    }, [_c('span', {
      staticClass: "page-text page-text--sm"
    }, [_vm._v(_vm._s(_vm.$dayjs(question === null || question === void 0 ? void 0 : question.createdAt).format("YYYY.MM.DD")))])]), _c('td', {
      staticClass: "board-list__txt",
      attrs: {
        "align": "center"
      }
    }, [_c('span', {
      staticClass: "page-text page-text--sm"
    }, [_vm._v(_vm._s(question === null || question === void 0 ? void 0 : question.viewCount))])])]);
  }), 0)])]), _c('v-row', {
    staticClass: "row--x-small flex-md-row-reverse h-lg-40px"
  }, [_c('v-col', {
    staticClass: "d-flex justify-end mb-10 mb-md-0",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "h-lg-40px",
    attrs: {
      "outlined": "",
      "color": "primary",
      "to": "/information/qna/create"
    }
  }, [_vm._v("질문하기")])], 1), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('v-row', {
    staticClass: "row--x-small search--primary"
  }, [_c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('v-select', {
    staticClass: "v-input--small w-100 w-md-120px",
    attrs: {
      "items": _vm.searchKeys,
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.filter.searchKey,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "searchKey", $$v);
      },
      expression: "filter.searchKey"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "9",
      "md": "auto"
    }
  }, [_c('v-text-field', {
    staticClass: "v-input--small w-100 w-md-240px",
    attrs: {
      "outlined": "",
      "hide-details": "",
      "placeholder": "검색어를 입력하세요.",
      "disabled": !_vm.filter.searchKey
    },
    on: {
      "input": function ($event) {
        return _vm.search(true);
      }
    },
    model: {
      value: _vm.filter.searchValue,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "searchValue", $$v);
      },
      expression: "filter.searchValue"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3",
      "md": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "h-100 w-100 min-w-md-60px",
    attrs: {
      "color": "grey-6"
    }
  }, [_c('span', {
    staticClass: "white--text font-size-14",
    on: {
      "click": function ($event) {
        return _vm.search();
      }
    }
  }, [_vm._v("검색")])])], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "v-pagination-wrap"
  }, [_c('v-pagination', {
    staticClass: "mt-4 mb-12",
    attrs: {
      "length": _vm.pageCount,
      "total-visible": 11
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', {
    staticClass: "d-none d-lg-table-header"
  }, [_c('tr', [_c('th', {
    staticStyle: {
      "width": "70px"
    }
  }, [_vm._v("No.")]), _c('th', {
    staticStyle: {
      "width": "70px"
    }
  }), _c('th', [_vm._v("제목")]), _c('th', {
    staticStyle: {
      "width": "9%"
    }
  }, [_vm._v("답변여부")]), _c('th', {
    staticStyle: {
      "width": "9%"
    }
  }, [_vm._v("날짜")]), _c('th', {
    staticStyle: {
      "width": "9%"
    }
  }, [_vm._v("조회수")])])]);

}]

export { render, staticRenderFns }