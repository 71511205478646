<template>
    <div class="board__list">
        <v-row no-gutters align="center" class="head-tab mb-10 mb-lg-20">
            <v-col cols="12" lg="" class="mb-20 mb-lg-0">
                <h3 class="font-size-20 font-size-lg-28">
                    묻고 답하기
                </h3>
            </v-col>
        </v-row>

        <div class="mb-20 mb-lg-40">
            <table class="board-list board-list--main">
                <thead class="d-none d-lg-table-header">
                    <tr>
                        <th style="width:70px;">No.</th>
                        <th style="width:70px;"></th>
                        <th>제목</th>
                        <th style="width:9%;">답변여부</th>
                        <th style="width:9%;">날짜</th>
                        <th style="width:9%;">조회수</th>
                    </tr>
                </thead>
                <tbody>
                    <!-- S:: 묻고답하기 -->
                    <tr v-for="question in questions" :key="question._id" @click="rowClick(question)">
                        <td align="center" class="board-list__txt board-list__txt--first">
                            {{ (page - 1) * limit + (+questions.indexOf(question) + 1) }}
                        </td>
                        <td align="center" class="board-list__txt">
                            <i v-if="!question.isPassword" class="icon icon-lockoff"></i>
                            <i v-else class="icon icon-lockon"></i>
                        </td>
                        <td align="center" class="board-list__tit">
                            <p class="grey-6--text page-text ellip">
                                {{ question?.subject }}
                            </p>
                        </td>
                        <td align="center" class="board-list__txt">
                            <span v-if="!question.reply.content" class="page-text page-text--sm">대기중</span>
                            <span v-else class="page-text page-text--sm primary--text">답변완료</span>
                        </td>
                        <td align="center" class="board-list__txt">
                            <span class="page-text page-text--sm">{{ $dayjs(question?.createdAt).format("YYYY.MM.DD") }}</span>
                        </td>
                        <td align="center" class="board-list__txt">
                            <span class="page-text page-text--sm">{{ question?.viewCount }}</span>
                        </td>
                    </tr>
                    <!-- E:: 묻고답하기 -->
                </tbody>
            </table>
        </div>

        <v-row class="row--x-small flex-md-row-reverse h-lg-40px">
            <v-col cols="12" md="auto" class="d-flex justify-end mb-10 mb-md-0">
                <v-btn outlined color="primary" class="h-lg-40px" to="/information/qna/create">질문하기</v-btn>
            </v-col>
            <v-spacer />
            <v-col cols="12" md="auto">
                <v-row class="row--x-small search--primary">
                    <v-spacer></v-spacer>
                    <v-col cols="12" md="auto">
                        <v-select v-model="filter.searchKey" :items="searchKeys" class="v-input--small w-100 w-md-120px" outlined hide-details></v-select>
                    </v-col>
                    <v-col cols="9" md="auto">
                        <v-text-field v-model="filter.searchValue" class="v-input--small w-100 w-md-240px" outlined hide-details placeholder="검색어를 입력하세요." :disabled="!filter.searchKey" @input="search(true)"/>
                    </v-col>
                    <v-col cols="3" md="auto">
                        <v-btn class="h-100 w-100 min-w-md-60px" color="grey-6">
                            <span class="white--text font-size-14" @click="search()">검색</span>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>            
        </v-row>

        <div class="v-pagination-wrap">
            <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="mt-4 mb-12"></v-pagination>
        </div>
    </div>
</template>

<script>
import api from '@/api'
import { mdiLockOutline, mdiLockOpenVariantOutline  } from '@mdi/js';
import SearchPrimary from "@/components/client/board/search--primary.vue";

export default {
    components: {
        SearchPrimary,
        mdiLockOutline,
        mdiLockOpenVariantOutline
    },

    data() {
        return {
            page: 1,
            limit: 10,
            pageCount: 0,

            questions: [],

            filter: {
                searchKey: this.$route.query.searchKey || "subjectcontent",
                searchValue: this.$route.query.searchValue || null,
            },
            searchKeys: [
                { text: "제목 + 내용",  value: "subjectcontent" },
                { text: "제목",  value: "subject" }
            ],
            questionsHeaders: [
                { text: "No.", align: "center", value: "no", width: 71},
                { text: "", align: "center", value: "isPassword", width: 71},
                { text: "제목", align: "center", value: "subject"},
                { text: "답변여부", align: "center", value: "reply.content", width: 111 },
                { text: "날짜", align: "center", value: "createdAt", width: 111 },
                { text: "조회수", align: "center", value: "viewCount", width: 111 },
            ]
        };
    },
    async mounted() {
        await this.init()
    },
    methods: {
        rowClick(question) {
            this.$router.push(`/information/qna/${question._id}`)
        },
        async init() {
            await this.search()
        },
        async search(routable = false){
            if(routable){
                this.$router.push({
                    query: { ...this.filter, page: this.page }
                });
                return;
            }
        
            var { summary, questions } = await api.v1.center.questions.gets({
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit
                },
                params: this.filter
            });
        
            this.questions = questions;
            this.summary = summary;
            this.pageCount = Math.ceil(summary.totalCount / this.limit);
        },
    }
};
</script>
<style lang="scss" scoped>
</style>